import debounce from 'lodash/debounce';

import { NEW_NOTIFICATION_EVENT_NAME, NO_NOTIFICATION_ID } from 'pages/notifications/constants';

export const checkNewNotification = debounce(async (response: Response): Promise<void> => {
    const newNotificationId = response.headers.get('New-Notifications');

    if (!newNotificationId) {
        const event = new CustomEvent(NEW_NOTIFICATION_EVENT_NAME, { detail: { notificationId: NO_NOTIFICATION_ID } });
        window.dispatchEvent(event);
        return;
    }

    const storedNotificationId = localStorage.getItem('notificationId');

    if (storedNotificationId === newNotificationId) {
        return;
    }

    localStorage.setItem('notificationId', newNotificationId);

    const event = new CustomEvent(NEW_NOTIFICATION_EVENT_NAME, { detail: { notificationId: newNotificationId } });
    window.dispatchEvent(event);
}, 300);

export const getNameFromEmail = (email: string): string => {
    const [namePart] = email.split('@');
    const [firstName, lastName] = namePart.split('.');

    return `${firstName} ${lastName}`;
};
